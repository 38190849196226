import React from 'react';
import { Form, Button, Divider, Icon, Table, Segment } from 'semantic-ui-react';
import { deleteData, getData, insertSimpleData, sendEmail, updateData } from '../../UtilComponents/requestsUtils';

const ArticleRequest = ({ issue_id, issue_status, serial_id, issue_title }) => {
    const formOrderDetailtInitialState = {
        id: '',
        issue_id: '',
        creation_date: '',
        issue_creator_id: '',
        order_placed_date: '',
        order_completed_date: '',
    };

    const formArticleState = {
        id: '',
        article_request_id: '',
        item_description: '',
        item_type: '',
        base_unit_of_measure: '',
        material: '',
        supplier_manufacturer: '',
        supplier_manufacturer_number: '',
    };

    const [orderDetails, setOrderDetails] = React.useState(formOrderDetailtInitialState);
    const [currentArticle, setCurrentArticle] = React.useState(formArticleState);
    const [articleList, setArticleList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [updateList, setUpdateList] = React.useState('');

    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, currentArticle, { [data.name]: data.value });
        setCurrentArticle(newObjState);
    };

    const resetFormArticle = () => {
        setCurrentArticle(formArticleState);
    };

    const submitFormArticle = () => {
        if (issue_status === 3) {
            alert('Der Artikel darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            if (orderDetails.order_placed_date) {
                alert('Der Artikel darf nicht bearbeitet werden.');
            } else {
                currentArticle.id ? updateArticle() : createArticle();
            }
        }
    };

    const createArticle = async () => {
        try {
            const body = {
                article_request_id: orderDetails.id,
                item_description: currentArticle.item_description,
                item_type: currentArticle.item_type,
                base_unit_of_measure: currentArticle.base_unit_of_measure,
                material: currentArticle.material,
                supplier_manufacturer: currentArticle.supplier_manufacturer,
                supplier_manufacturer_number: currentArticle.supplier_manufacturer_number,
            };
            await insertSimpleData('crud/issues/articles/save_article', body);
            setCurrentArticle(formArticleState);
            setLoading(false);
            setUpdateList('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };
    const updateArticle = async () => {
        try {
            const where = { id: currentArticle.id };
            const body = {
                article_request_id: orderDetails.id,
                item_description: currentArticle.item_description,
                item_type: currentArticle.item_type,
                base_unit_of_measure: currentArticle.base_unit_of_measure,
                material: currentArticle.material,
                supplier_manufacturer: currentArticle.supplier_manufacturer,
                supplier_manufacturer_number: currentArticle.supplier_manufacturer_number,
            };
            await updateData('crud/issues/articles/update_article', body, where);
            setCurrentArticle(formArticleState);
            setLoading(false);
            setUpdateList('afterUpdate' + Date.now().toString());
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };

    const itemType = [
        { key: 'Produktion', value: 'Produktion', text: 'Produktion' },
        { key: 'Kaufteil', value: 'Kaufteil', text: 'Kaufteil' },
    ];

    const submitOrder = async () => {
        if (articleList.length === 0) {
            alert('Der Antrag darf nicht stellen werden. Es muss mindestens ein Artikel vorhanden sein.');
        } else {
            setLoading(true);
            try {
                const where = { id: orderDetails.id };
                const body = {
                    order_placed_date: new Date().toISOString(),
                };
                await updateData('crud/issues/articles/update_order', body, where);
                setCurrentArticle(formArticleState);
                setLoading(false);
                setUpdateList('afterUpdate' + Date.now().toString());
                const emailPayload = {
                    template_id: 'meldungen_artikelantrag',
                    // TODO: Please uncomment when it is deployed to production.
                    // to: ['arbeitsvorbereitung@krebsundaulich.de'],
                    to: ['arteta@deeping.de', 'roehrs@deeping.de'],
                    subject: `Neuer Artikelantrag - ${serial_id} - Artikelantrag - ${issue_title}`,
                    body: `<b>${serial_id} - Artikelantrag - ${issue_title}</b><br/>Neuer Artikelantrag<br/>`,
                };
                sendEmail(emailPayload);
            } catch (error) {
                console.log('error', error);
                setLoading(false);
            }
        }
    };

    const editArticle = article => {
        if (orderDetails.order_placed_date) {
            alert('Der Artikel darf nicht bearbeitet werden.');
        } else {
            setCurrentArticle(article);
        }
    };

    const deleteArticle = async article => {
        if (orderDetails.order_placed_date) {
            alert('Der Artikel darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            try {
                const where = { id: article.id, article_request_id: article.article_request_id };
                await deleteData('crud/issues/articles/delete_article', where);
                setUpdateList('afterDeletion' + Date.now().toString());
                setLoading(false);
            } catch (error) {
                console.log('error', error);
                setLoading(false);
            }
        }
    };

    const isFormValid = () => {
        return currentArticle.item_description != '' && currentArticle.item_type != '';
    };

    React.useEffect(() => {
        const loadArticles = async () => {
            setLoading(true);
            try {
                const orderDetailResponse = await getData(
                    'crud/issues/articles/get_order_by_issue?issue_id=' + issue_id,
                );
                if (orderDetailResponse.length > 0) {
                    setOrderDetails(orderDetailResponse[0]);
                    const articleListResponse = await getData(
                        'crud/issues/articles/get_articles_by_article_request_id?article_request_id=' +
                            orderDetailResponse[0].id,
                    );
                    setArticleList(articleListResponse);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('error', error);
            }
        };
        loadArticles();
    }, [updateList]);

    const backgroundTable = { 'background-color': '#8f8f8f' };

    return (
        <>
            <Segment basic loading={loading}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Artikelbezeichnung</Table.HeaderCell>
                            <Table.HeaderCell>Artikeltyp</Table.HeaderCell>
                            <Table.HeaderCell>Basismengeneinheit</Table.HeaderCell>
                            <Table.HeaderCell>Material</Table.HeaderCell>
                            <Table.HeaderCell>Lieferant/Hersteller</Table.HeaderCell>
                            <Table.HeaderCell>Lieferant/Hersteller Nummer</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {articleList.length === 0 && (
                            <Table.Row>
                                <Table.Cell colSpan="7" textAlign="center">
                                    Es gibt keinen Artikel
                                </Table.Cell>
                            </Table.Row>
                        )}
                        {articleList.map(row => {
                            return (
                                <Table.Row key={row.id}>
                                    <Table.Cell>{row.item_description}</Table.Cell>
                                    <Table.Cell>{row.item_type}</Table.Cell>
                                    <Table.Cell style={row.item_type == 'Produktion' ? backgroundTable : {}}>
                                        {row.base_unit_of_measure}
                                    </Table.Cell>
                                    <Table.Cell style={row.item_type == 'Produktion' ? backgroundTable : {}}>
                                        {row.material}
                                    </Table.Cell>
                                    <Table.Cell style={row.item_type == 'Produktion' ? backgroundTable : {}}>
                                        {row.supplier_manufacturer}
                                    </Table.Cell>
                                    <Table.Cell style={row.item_type == 'Produktion' ? backgroundTable : {}}>
                                        {row.supplier_manufacturer_number}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            name="edit"
                                            link
                                            onClick={() => {
                                                editArticle(row);
                                            }}
                                        />
                                        <Icon name="remove" link color="red" onClick={() => deleteArticle(row)} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <Divider />
                <Form>
                    <Form.Input
                        key="item_description"
                        placeholder="Artikelbezeichnung"
                        name="item_description"
                        value={currentArticle.item_description}
                        width={12}
                        onChange={handleChange}
                    />
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Form.Select
                                placeholder="Artikeltyp"
                                key="item_type"
                                name="item_type"
                                options={itemType}
                                onChange={handleChange}
                                value={currentArticle.item_type}
                            />
                        </Form.Field>
                        {currentArticle.item_type === 'Kaufteil' && (
                            <>
                                <Form.Field>
                                    <Form.Input
                                        key="base_unit_of_measure"
                                        placeholder="Basismengeneinheit"
                                        name="base_unit_of_measure"
                                        value={currentArticle.base_unit_of_measure}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        key="material"
                                        placeholder="Material"
                                        name="material"
                                        value={currentArticle.material}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        key="supplier_manufacturer"
                                        placeholder="Lieferant/Hersteller"
                                        name="supplier_manufacturer"
                                        value={currentArticle.supplier_manufacturer}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        key="supplier_manufacturer_number"
                                        placeholder="Lieferant/Hersteller Nummer"
                                        name="supplier_manufacturer_number"
                                        value={currentArticle.supplier_manufacturer_number}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </>
                        )}
                    </Form.Group>
                </Form>
                <br />
                <Button.Group fluid>
                    <Button primary onClick={submitFormArticle} disabled={!isFormValid()}>
                        Artikel speichern
                    </Button>
                    <Button disabled={loading} onClick={resetFormArticle}>
                        Abbrechen
                    </Button>
                </Button.Group>
                <Divider />
                <br />
                <Button fluid primary onClick={submitOrder}>
                    Antrag stellen
                </Button>
            </Segment>
        </>
    );
};
export default ArticleRequest;

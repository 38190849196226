import { COLORS } from './global-styles';
// export const apiUrl = 'https://api.nuc1-uapvfzpoxqbwqhjkpqfo.deeping-services.de';
export const apiUrl = 'https://test.api.kua.uapvfzpoxqbwqhjkpqfo.deeping-services.de';
// export const apiUrl = 'http://localhost/test-kua-rest-api';

// export const apiUrl = 'http://127.0.0.1:8080';
export const backendUrl = apiUrl;
export const queryIdTaZug = 1;
export const queryIdPpsOrderId = 6;
export const queryIdYear = 7;

export const ChartLabelColors = [
    COLORS.RED_KUA,
    'rgb(185,184,181)', // grey
    //'rgb(251,201,80)', // yellow
    '#F3CAC9',
    'rgb(96,95,94)', // dark grey
    'rgb(227,74,107)', // rosa
    'rgb(87,35,100)', // purpel
    'rgb(0,143,57)', // green
];

import React from 'react';
import { Form, Button, Divider, Icon, Table, Select, Segment } from 'semantic-ui-react';
import { getCurrentUserId, getData, updateData, sendEmail } from '../../UtilComponents/requestsUtils';

const ArticleCreationForm = ({ issue_id, issue_status, serial_id, issue_title, creator_email }) => {
    //console.log(creator_email);
    const formOrderDetailtInitialState = {
        id: '',
        issue_id: '',
        creation_date: '',
        issue_creator_id: '',
        order_placed_date: '',
        order_completed_date: '',
    };

    const formArticleState = {
        id: '',
        article_request_id: '',
        item_description: '',
        item_type: '',
        base_unit_of_measure: '',
        material: '',
        supplier_manufacturer: '',
        supplier_manufacturer_number: '',
        item_number: '',
        done_date: '',
        article_creator_id: '',
    };

    const [orderDetails, setOrderDetails] = React.useState(formOrderDetailtInitialState);
    const [currentArticle, setCurrentArticle] = React.useState(formArticleState);
    const [articleList, setArticleList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [updateList, setUpdateList] = React.useState('');

    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, currentArticle, { [data.name]: data.value });
        setCurrentArticle(newObjState);
    };

    const resetFormArticle = () => {
        setCurrentArticle(formArticleState);
    };

    const submitFormArticle = () => {
        if (issue_status === 3) {
            alert('Der Artikel darf nicht bearbeitet werden.');
        } else {
            if (orderDetails.order_completed_date) {
                alert('Der Artikel darf nicht bearbeitet werden.');
            } else {
                updateArticle();
            }
        }
    };

    const updateArticle = async () => {
        try {
            setLoading(true);
            const where = { id: currentArticle.id };
            const body = {
                item_description: currentArticle.item_description,
                item_number: currentArticle.item_number,
                done_date: new Date().toISOString(),
                article_creator_id: getCurrentUserId(),
            };
            await updateData('crud/issues/articles/update_article', body, where);
            setCurrentArticle(formArticleState);
            setLoading(false);
            setUpdateList('afterUpdate' + Date.now().toString());
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };

    const submitOrder = async () => {
        if (articleList.length === 0) {
            alert('Der Antrag kann nicht abgeschlosen werden. Es muss mindestens ein Artikel vorhanden sein.');
        } else {
            try {
                const where = { id: orderDetails.id };
                const body = {
                    order_completed_date: new Date().toISOString(),
                };
                await updateData('crud/issues/articles/update_order', body, where);
                setCurrentArticle(formArticleState);
                setLoading(false);
                setUpdateList('afterUpdate' + Date.now().toString());
                const emailPayload = {
                    template_id: 'meldungen_artikelantrag',
                    // TODO: Please uncomment when it is deployed to production.
                    // to: [creator_email],
                    to: ['arteta@deeping.de', 'roehrs@deeping.de'],
                    subject: `Artikel angelegt - ${serial_id} - Artikelantrag - ${issue_title}`,
                    body: `<b>${serial_id} - Artikelantrag - ${issue_title}</b><br/>Artikelantrag abgeschlossen.<br/>`,
                };
                sendEmail(emailPayload);
            } catch (error) {
                console.log('error', error);
                setLoading(false);
            }
        }
    };

    const editArticle = article => {
        if (orderDetails.order_completed_date) {
            alert('Der Artikel darf nicht bearbeitet werden.');
        } else {
            setCurrentArticle(article);
        }
    };

    const isFormValid = () => {
        return currentArticle.item_description != '' && currentArticle.item_number != '';
    };

    React.useEffect(() => {
        const loadArticles = async () => {
            setLoading(true);
            try {
                const orderDetailResponse = await getData(
                    'crud/issues/articles/get_order_by_issue?issue_id=' + issue_id,
                );
                if (orderDetailResponse.length > 0) {
                    setOrderDetails(orderDetailResponse[0]);
                    const articleListResponse = await getData(
                        'crud/issues/articles/get_articles_by_article_request_id?article_request_id=' +
                            orderDetailResponse[0].id,
                    );
                    setArticleList(articleListResponse);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log('error', error);
            }
        };
        loadArticles();
    }, [updateList]);

    const validateOrder = () => {
        const articleListWithCreationDate = articleList.filter(article => article.done_date);
        return !orderDetails.order_placed_date || articleListWithCreationDate.length != articleList.length;
    };

    return (
        <>
            <Segment basic loading={loading}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Artikelbezeichnung</Table.HeaderCell>
                            <Table.HeaderCell>Artikeltyp</Table.HeaderCell>
                            <Table.HeaderCell>Basismengeneinheit</Table.HeaderCell>
                            <Table.HeaderCell>Material</Table.HeaderCell>
                            <Table.HeaderCell>Lieferant/Hersteller</Table.HeaderCell>
                            <Table.HeaderCell>Lieferant/Hersteller Nummer</Table.HeaderCell>
                            <Table.HeaderCell>Artikelnummer</Table.HeaderCell>
                            <Table.HeaderCell>Erledigt am</Table.HeaderCell>
                            <Table.HeaderCell>Durch</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {articleList.length === 0 && (
                            <Table.Row>
                                <Table.Cell colSpan="7" textAlign="center">
                                    Es gibt keinen Artikel
                                </Table.Cell>
                            </Table.Row>
                        )}
                        {articleList.map(row => {
                            return (
                                <Table.Row key={row.id}>
                                    <Table.Cell>{row.item_description}</Table.Cell>
                                    <Table.Cell>{row.item_type}</Table.Cell>
                                    <Table.Cell>{row.base_unit_of_measure}</Table.Cell>
                                    <Table.Cell>{row.material}</Table.Cell>
                                    <Table.Cell>{row.supplier_manufacturer}</Table.Cell>
                                    <Table.Cell>{row.supplier_manufacturer_number}</Table.Cell>
                                    <Table.Cell>{row.item_number}</Table.Cell>
                                    <Table.Cell>
                                        {row.done_date ? new Date(row.done_date).toLocaleDateString('de') : '-'}
                                    </Table.Cell>
                                    <Table.Cell>{row.created_by}</Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            name="edit"
                                            link
                                            onClick={() => {
                                                editArticle(row);
                                            }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <Divider />
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Form.Input
                                key="item_description"
                                placeholder="Artikelbezeichnung"
                                name="item_description"
                                value={currentArticle.item_description}
                                onChange={handleChange}
                                readOnly={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                key="item_number"
                                placeholder="Artikelnummer"
                                name="item_number"
                                value={currentArticle.item_number}
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <br />
                <Button.Group fluid>
                    <Button primary onClick={submitFormArticle} disabled={!isFormValid()}>
                        Artikel speichern
                    </Button>
                    <Button disabled={loading} onClick={resetFormArticle}>
                        Abbrechen
                    </Button>
                </Button.Group>
                <Divider />
                <br />
                <Button fluid primary onClick={submitOrder} disabled={validateOrder()}>
                    Antrag abschließen
                </Button>
            </Segment>
        </>
    );
};
export default ArticleCreationForm;

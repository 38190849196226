import React from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';
import { getData, insertSimpleData, updateData, sendEmail } from '../../UtilComponents/requestsUtils';
import SInput from '../../UtilComponents/SearchableInput';
import { queryUsers } from '../Queries';
import MultiSelectWithSearchAsync from '../../UtilComponents/MultiSelectWithSearchAsync';

const comp = ({ issue_id, issue_type_id, issue_status, numberOfTabsToShow, setNumberOfTabsToShow }) => {
    // when there is not numberOfTabsToShow passed as an argument is issue_type_id == 2 @Fehler.
    const [loading, setLoading] = React.useState(false);

    const [formData, setFormData] = React.useState({
        change_responsible_id: '',
        change_actor_id: '',
        change_type_id: '',
        article_numbers: [], // Zu ändernde Artikel
        related_article_numbers: [], // Teileverwendung
        change_reasons_description: '',
        change_description: '',
        rest_material_action_id: '',
    });

    const [formFieldsValidity, setFormFieldsValidity] = React.useState({
        change_responsible_id: true,
        change_actor_id: true,
        change_type_id: true,
        article_numbers: true,
        related_article_numbers: true,
        change_reasons_description: true,
        change_description: true,
        rest_material_action_id: true,
    });

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        //if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    React.useEffect(() => {
        const getChangeDetails = async () => {
            setLoading(true);
            try {
                const response = await getData(`crud/changes/get_change_details?issue_id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getChangeDetails();
    }, []);

    const createDetails = async () => {
        setLoading(true);
        const { article_numbers, related_article_numbers, ...rest } = formData;
        const body = {
            ...rest,
            issue_id: issue_id,
        };
        try {
            const response = await insertSimpleData('crud/changes/post_change_details', body);
            setFormData({ ...response, article_numbers, related_article_numbers });
            if (numberOfTabsToShow) setNumberOfTabsToShow(numberOfTabsToShow + 1);
            sendNotification();
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateDetails = async () => {
        setLoading(true);
        const { issue_id, article_numbers, related_article_numbers, change_details_id, ...rest } = formData;
        try {
            const response = await updateData('crud/changes/update_change_details', rest, {
                change_details_id: change_details_id,
            });
            setFormData({ ...response, article_numbers, related_article_numbers });
            sendNotification();
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const sendNotification = async () => {
        //9. Erstellt
        const status_id = 9;
        const emailStatus = await getData('crud/changes/get_email_status?issue_id=' + issue_id);
        if (emailStatus[0].email_sent_status[0] == null || !emailStatus[0].email_sent_status.includes(status_id)) {
            const issueInfoForEmail = await getData(`crud/issues/get_issue_info_for_email?issue_id=${issue_id}`);
            const issueDetail = issueInfoForEmail[0];
            const articleNumbers = issueDetail.article_numbers ? issueDetail.article_numbers.join(', ') : '-';
            const body = `<h3>Titel:</h3>${issueDetail.issue_title}<br/><br/>
                            <h3>Verantwortlicher:</h3>${issueDetail.responsible_name}<br/><br/>
                            <h3>Beschreibung der Änderung​sgrundes:</h3>${issueDetail.change_reasons_description}<br/><br/>
                            <h3>Beschreibung der Änderung:</h3>${issueDetail.change_description}<br/><br/>
                            <h3>Umgang mit Restmaterial​​:</h3>${issueDetail.rest_material_action}<br/><br/>
                            <h3>Zu ändernde Artikel​:</h3>${articleNumbers}<br/><br/>
                            `;

            let to = [];
            let subject = '';
            let template_id = '';
            //issue_type_id == 3 @Änderung OR issue_type_id == 11 @Sammeländerung OR issue_type_id == 12 @Bauabweichung
            if (issue_type_id === 3 || issue_type_id === 11 || issue_type_id === 12) {
                const emailTemplates = {
                    3: {
                        subject: 'Neue Änderung: ',
                        template_id: 'meldungen_nach_erstellung_aenderung',
                    },
                    11: {
                        subject: 'Neue Sammeländerung: ',
                        template_id: 'meldungen_nach_erstellung_sammelaenderung',
                    },
                    12: {
                        subject: 'Neue Bauabweichung: ',
                        template_id: 'meldungen_nach_erstellung_bauabweichung',
                    },
                };
                subject = emailTemplates[issue_type_id].subject; //issue_type_id === 3 ? 'Neue Änderung: ' : 'Neue Sammeländerung: ';
                template_id = emailTemplates[issue_type_id].template_id; // issue_type_id === 3 ? 'meldungen_nach_erstellung_aenderung' : 'meldungen_nach_erstellung_sammelaenderung';
                to = ['arteta@deeping.de', 'garcia@deeping.de', 'roehrs@deeping.de'];
                // TODO: Please uncomment when it is deployed to production.
                // to = [
                //     't.lipsius@krebsundaulich.de',
                //     'berechnung@krebsundaulich.de',
                //     'c.sievert@krebsundaulich.de',
                //     'r.moshake@krebsundaulich.de',
                //     'arbeitsvorbereitung@krebsundaulich.de',
                //     'konstruktion@krebsundaulich.de​',
                //     's.krummhaar@krebsundaulich.de',
                //     't.krug@krebsundaulich.de',
                //     'y.fulst@krebsundaulich.de',
                //     'mechanik@krebsundaulich.de',
                //     'einkauf@krebsundaulich.de',
                // ];
            } else {
                //issue_type_id == 10 @Quickchange Extern
                subject = 'Neuer Quickchange Extern: ';
                template_id = 'meldungen_nach_erstellung_quickchange';
                to = ['arteta@deeping.de', 'roehrs@deeping.de'];
                // TODO: Please uncomment when it is deployed to production.
                // to = ['arbeitsvorbereitung@krebsundaulich.de', 'konstruktion@krebsundaulich.de'];
            }
            const payload = {
                template_id: template_id,
                to: to,
                subject: subject + issueDetail.serial_id + ' - ' + issueDetail.issue_title,
                body: body,
            };
            sendEmail(payload);
            insertSimpleData('crud/changes/post_email_send', { issue_id: issue_id, email_status: status_id });
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.change_details_id ? createDetails() : updateDetails();
        }
    };

    return (
        <Form loading={loading}>
            <Form.Group>
                <SInput
                    label="Verantwortlicher"
                    placeholder="Verantwortlicher auswählen"
                    name="change_responsible_id"
                    value={formData.change_responsible_id}
                    onChange={handleChange}
                    query={queryUsers}
                    width={5}
                    required={false}
                />

                <SInput
                    label="Änderung veranlasst durch"
                    placeholder="Änderung veranlasst durch"
                    name="change_actor_id"
                    value={formData.change_actor_id}
                    onChange={handleChange}
                    query="crud/changes/get_changes_actors"
                    width={5}
                    required={false}
                />

                <SInput
                    label="Änderung"
                    placeholder="Änderungstyp auswählen"
                    name="change_type_id"
                    value={formData.change_type_id}
                    onChange={handleChange}
                    query="crud/changes/get_changes_types"
                    width={6}
                    required={false}
                />
            </Form.Group>
            <Form.Group>
                <MultiSelectWithSearchAsync
                    label="Zu ändernde Artikel"
                    tooltip="Hier bitte die Artikelnummern auswählen, welche Artikel geändert werden sollen​"
                    placeholder="Betroffene Artikel auswählen"
                    name="article_numbers"
                    query="crud/changes/get_article_numbers"
                    primaryKey="issue_id"
                    primaryKeyValue={issue_id}
                    foreignKey="article_number"
                    additionQuery="crud/changes/add_article_to_change"
                    deletionQuery="crud/changes/delete_article_from_change"
                    value={formData.article_numbers}
                    handleChange={handleChange}
                    width={5}
                    fluid={false}
                    required={false}
                />
                <MultiSelectWithSearchAsync
                    label="Teileverwendung"
                    tooltip="Falls bekannt ist, in welchen Artikeln (z.B. Baugruppen) der zu ändernde Artikel verwendet wird, kann hier der jeweilige Artikel ausgewählt werden. Falls nicht bekannt, muss dies nicht ausgefüllt werden."
                    placeholder="Betroffene Artikel auswählen"
                    name="related_article_numbers"
                    query="crud/changes/get_article_numbers"
                    primaryKey="issue_id"
                    primaryKeyValue={issue_id}
                    foreignKey="related_article_number"
                    additionQuery="crud/changes/add_related_article_to_change"
                    deletionQuery="crud/changes/delete_related_article_from_change"
                    value={formData.related_article_numbers}
                    handleChange={handleChange}
                    width={5}
                    fluid={false}
                    required={false}
                />
                <SInput
                    label="Umgang mit Restmaterial"
                    placeholder="Umgang auswählen"
                    name="rest_material_action_id"
                    value={formData.rest_material_action_id}
                    onChange={handleChange}
                    query="crud/changes/get_rest_material_actions"
                    width={6}
                    required={false}
                />
            </Form.Group>

            <Form.TextArea
                label={
                    <Popup
                        content="Hier sollte genau erklärt werden, warum der Artikel geändert werden soll, damit z.B. auch mögliche Alternativen diskutiert werden können.​"
                        trigger={<label>Beschreibung des Änderungsgrundes</label>}
                    />
                }
                name="change_reasons_description"
                rows={4}
                value={formData.change_reasons_description}
                onChange={handleChange}
            />

            <Form.TextArea
                label={
                    <Popup
                        content="Bitte die Änderung möglichst genau beschreiben, damit es nicht zu Folgefehlern kommen kann.​"
                        trigger={<label>Beschreibung der Änderung</label>}
                    />
                }
                name="change_description"
                rows={4}
                value={formData.change_description}
                onChange={handleChange}
            />

            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};
export default comp;
